const Profissional = ({info, size}) => {
    return (
        <div className={`col-lg-${size} col-md-6 col-12`}>
            <div className="single-doctor wow fadeInUp" data-wow-delay=".4s">
                <div className="image">
                    <img src={info.image ? `/assets/images/doctors/${info.image}` : "/assets/images/doctors/doctor.png"} alt={info.prefixo ? `${info.prefixo} ${info.nome}`: info.nome}/>
                </div>
                <div className="content">
                    <h5>{info.especialidade}</h5>
                    <h3>{info.nome}</h3>
                </div>
            </div>
        </div>
    )
}

const RowProfissionais = ({items, size}) => {
    return (
        <div className="row">
            {items.map((it, i) => <Profissional key={i} info={it} size={size} />)}
        </div>
    )
}

const PorfolioProfissionais = ({profissionais, itemsPerRow}) => {
    const chunk = (arr, len) => {
        let chunks = [],
            i = 0,
            n = arr.length;

        while (i < n) {
            chunks.push(arr.slice(i, i += len));
        }

        return chunks;
    }

    let rows = chunk(profissionais, itemsPerRow)
    return (
        <>
            {rows.map((row, i) => <RowProfissionais key={`row-profissionais-${i}`} size={12 / itemsPerRow} items={row} />)}
        </>
    )
}

const Lst = () => {
    return (

        <>
            <div className="row">
                <div className="col-lg-3 col-md-6 col-12">
                    <div className="single-doctor wow fadeInUp" data-wow-delay=".4s">
                        <div className="image">
                            <img src="assets/images/doctors/AlexandraSilva.png" alt="Dra. Alexandra Silva" />
                        </div>
                        <div className="content">
                            <h5> Fisioterapeuta</h5>
                            <h3>Alexandra Silva</h3>
                        </div>
                    </div>
                </div>

                <div className="col-lg-3 col-md-6 col-12">
                    <div className="single-doctor wow fadeInUp" data-wow-delay=".2s">
                        <div className="image">
                            <img src="assets/images/doctors/ÁlvaroMachado.png" alt="Dr. Álvaro Machado" />
                        </div>
                        <div className="content">
                            <h5>Dermatologista</h5>
                            <h3>Álvaro Machado</h3>
                        </div>
                    </div>
                </div>

                <div className="col-lg-3 col-md-6 col-12">
                    <div className="single-doctor wow fadeInUp" data-wow-delay=".6s">
                        <div className="image">
                            <img src="assets/images/doctors/AlvaroRodrigues.png" alt="Foto Dr.Álvaro Rodrigues" />
                        </div>
                        <div className="content">
                            <h5> Estomatologia</h5>
                            <h3>Álvaro Rodrigues</h3>
                        </div>
                    </div>
                </div>

                <div className="col-lg-3 col-md-6 col-12">
                    <div className="single-doctor wow fadeInUp" data-wow-delay=".6s">
                        <div className="image">
                            <img src="assets/images/doctors/CeciliaSilva.png" alt="Dra. Cecília Silva" />
                        </div>
                        <div className="content">
                            <h5>Fisioterapeuta</h5>
                            <h3>Cecília Silva</h3>
                        </div>
                    </div>
                </div>

            </div>
            <div className="row">
                <div className="col-lg-3 col-md-6 col-12">
                    <div className="single-doctor wow fadeInUp" data-wow-delay=".2s">
                        <div className="image">
                            <img src="assets/images/doctors/none.png" alt="Dra. Cláudia Gemelg" />
                        </div>
                        <div className="content">
                            <h5> Medicina Física e de Reabilitação</h5>
                            <h3>Cláudia Gemelgo</h3>
                        </div>
                    </div>
                </div>

                <div className="col-lg-3 col-md-6 col-12">
                    <div className="single-doctor wow fadeInUp" data-wow-delay=".8s">
                        <div className="image">
                            <img src="assets/images/doctors/DanielDuarte.png" alt="Dr.Daniel Duarte" />
                        </div>
                        <div className="content">
                            <h5>Psicólogo</h5>
                            <h3>Daniel Duarte</h3>
                        </div>
                    </div>
                </div>

                <div className="col-lg-3 col-md-6 col-12">
                    <div className="single-doctor wow fadeInUp" data-wow-delay=".4s">
                        <div className="image">
                            <img src="assets/images/doctors/DanielTeixeira.png" alt="Enf. Daniel Teixeira" />
                        </div>
                        <div className="content">
                            <h5>Enfermeiro</h5>
                            <h3>Daniel Teixeira</h3>
                        </div>
                    </div>
                </div>

                <div className="col-lg-3 col-md-6 col-12">
                    <div className="single-doctor wow fadeInUp" data-wow-delay=".4s">
                        <div className="image">
                            <img src="assets/images/doctors/none.png" alt="Enf. Daniel Teixeira" />
                        </div>
                        <div className="content">
                            <h5>Personal Trainer</h5>
                            <h3>Elsa Cruz</h3>
                        </div>
                    </div>
                </div>

            </div>
            <div className="row">
                <div className="col-lg-3 col-md-6 col-12">
                    <div className="single-doctor wow fadeInUp" data-wow-delay=".6s">
                        <div className="image">
                            <img src="assets/images/doctors/FilipaSousa.png" alt="Foto Dra. Filipa Sousa" />
                        </div>
                        <div className="content">
                            <h5>Terapeuta Ocupacional</h5>
                            <h3>Filipa Sousa</h3>
                        </div>
                    </div>
                </div>

                <div className="col-lg-3 col-md-6 col-12">
                    <div className="single-doctor wow fadeInUp" data-wow-delay=".8s">
                        <div className="image">
                            <img src="assets/images/doctors/GabrielaRodrigues.png"
                                 alt="Dra. Gabriela Rodrigues" />
                        </div>
                        <div className="content">
                            <h5>Gerontóloga</h5>
                            <h3>Gabriela Rodrigues</h3>
                        </div>
                    </div>
                </div>

                <div className="col-lg-3 col-md-6 col-12">
                    <div className="single-doctor wow fadeInUp" data-wow-delay=".2s">
                        <div className="image">
                            <img src="assets/images/doctors/JoanaLages.png" alt="Dra. Joana Lages" />
                        </div>
                        <div className="content">
                            <h5>Pneumologista</h5>
                            <h3>Joana Lages</h3>
                        </div>
                    </div>
                </div>

                <div className="col-lg-3 col-md-6 col-12">
                    <div className="single-doctor wow fadeInUp" data-wow-delay=".4s">
                        <div className="image">
                            <img src="assets/images/doctors/none.png" alt="Enf. Daniel Teixeira" />
                        </div>
                        <div className="content">
                            <h5>Enfermeiro</h5>
                            <h3>Jorge Sousa</h3>
                        </div>
                    </div>
                </div>

            </div>
            <div className="row">
                <div className="col-lg-3 col-md-6 col-12">
                    <div className="single-doctor wow fadeInUp" data-wow-delay=".2s">
                        <div className="image">
                            <img src="assets/images/doctors/none.png" alt="Dra. Judite Sousa" />
                        </div>
                        <div className="content">
                            <h5> Medicina Geral e Familiar</h5>
                            <h3>Judite Sousa</h3>
                        </div>
                    </div>
                </div>

                <div className="col-lg-3 col-md-6 col-12">
                    <div className="single-doctor wow fadeInUp" data-wow-delay=".4s">
                        <div className="image">
                            <img src="assets/images/doctors/LilianaVasconcelos.png" alt="Dra. Liliana Vasconcelos" />
                        </div>
                        <div className="content">
                            <h5>Podologista</h5>
                            <h3>Liliana Vasconcelos</h3>
                        </div>
                    </div>
                </div>

                <div className="col-lg-3 col-md-6 col-12">
                    <div className="single-doctor wow fadeInUp" data-wow-delay=".6s">
                        <div className="image">
                            <img src="assets/images/doctors/MargaridaCosta.png" alt="Dra. Margarida da Costa" />
                        </div>
                        <div className="content">
                            <h5>Nutricionista</h5>
                            <h3>Margarida da Costa</h3>
                        </div>
                    </div>
                </div>

                <div className="col-lg-3 col-md-6 col-12">
                    <div className="single-doctor wow fadeInUp" data-wow-delay=".8s">
                        <div className="image">
                            <img src="assets/images/doctors/none.png" alt="Dra. Margarida Marque" />
                        </div>
                        <div className="content">
                            <h5>Medicina Geral e Familiar</h5>
                            <h3>Margarida Marques</h3>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-3 col-md-6 col-12">
                    <div className="single-doctor wow fadeInUp" data-wow-delay=".4s">
                        <div className="image">
                            <img src="assets/images/doctors/MartaRibeiroSilva.png" alt="Dra. Marta Ribeiro Silva" />
                        </div>
                        <div className="content">
                            <h5>Pediatra</h5>
                            <h3>Marta Ribeiro Silva</h3>
                        </div>
                    </div>
                </div>

                <div className="col-lg-3 col-md-6 col-12">
                    <div className="single-doctor wow fadeInUp" data-wow-delay=".8s">
                        <div className="image">
                            <img src="assets/images/doctors/PatriciaRodrigues.png" alt="Dra.Patrícia Rodrigues" />
                        </div>
                        <div className="content">
                            <h5>Optometrista</h5>
                            <h3>Patrícia Rodrigues</h3>
                        </div>
                    </div>
                </div>

                <div className="col-lg-3 col-md-6 col-12">
                    <div className="single-doctor wow fadeInUp" data-wow-delay=".2s">
                        <div className="image">
                            <img src="assets/images/doctors/none.png" alt="Dr. Sérgio Ferreira" />
                        </div>
                        <div className="content">
                            <h5>Podologista</h5>
                            <h3>Sérgio Ferreira</h3>
                        </div>
                    </div>
                </div>

                <div className="col-lg-3 col-md-6 col-12">
                    <div className="single-doctor wow fadeInUp" data-wow-delay=".6s">
                        <div className="image">
                            <img src="assets/images/doctors/SoniaAlmeida.png" alt="Sonia Almeida" />
                        </div>
                        <div className="content">
                            <h5>Administrativa</h5>
                            <h3>Sónia Almeida</h3>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-3 col-md-6 col-12">
                    <div className="single-doctor wow fadeInUp" data-wow-delay=".4s">
                        <div className="image">
                            <img src="assets/images/doctors/TiagoFernandes.png" alt="Dr.Tiago Fernandes" />
                        </div>
                        <div className="content">
                            <h5> Oftalmologia</h5>
                            <h3>Tiago Fernandes</h3>
                        </div>
                    </div>
                </div>

                <div className="col-lg-3 col-md-6 col-12">
                    <div className="single-doctor wow fadeInUp" data-wow-delay=".2s">
                        <div className="image">
                            <img src="assets/images/doctors/none.png" alt="Dra. Joana Lages" />
                        </div>
                        <div className="content">
                            <h5>Osteopata</h5>
                            <h3>Tiago Ribeiro</h3>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default PorfolioProfissionais