const list = [
    {
        nome: "Alexandra Silva",
        image: "AlexandraSilva.png",
        prefixo: "Dra.",
        especialidade: "Fisioterapeuta",
        especialidades: ["fisioterapia"],

        description: "Fisioterapeuta especializada em Saúde da Mulher",
        areas: [
            "Reabilitação das Disfunções do Pavimento Pélvico",
            "Intervenção na gravidez, pós-parto e menopausa"
        ]
    },
    {
        nome: "Andreia Familiar",
        image: "AndreiaFamiliar.jpeg",
        prefixo: "Enf.",
        especialidade: "Enfermeira",
        especialidades: ["enfermagem"],

        areas: [
            "Reabilitação cardiorespiratória"
        ]
    },

    ////image: "ÁlvaroMachado.png",
      //  prefixo: "Dr.",
      //  nome: "Álvaro Machado",
        //especialidade: "Dermatologista",
      //  especialidades: ["dermatologia"],

      //  description: "Dermatologista"
  //  },

     {
        image: "AlvaroRodrigues.png",
        prefixo: "Dr.",
        nome: "Álvaro Rodrigues",
        especialidade: "Estomatologia",
        especialidades: ["estomatologia"],

        description: "Médico Especialista em Estomatologia com diferenciação na área da disfunção da articulação temporomandibular\n"
    },
    // {
    //     image: "CeciliaSilva.png",
    //     prefixo: "Dra.",
    //     nome: "Cecília Silva",
    //     especialidade: "Fisioterapeuta",
    //     especialidades: ["fisioterapia"],
    //     description: "Fisioterapeuta",
    //     education: [
    //         "Pós-Graduação em Fisioterapia Cardiorrespiratória",
    //         "Pilates Clínico",
    //         "Formação em Terapias Miofasciais e Trigger Points"
    //     ]
    // },
    {
        prefixo: "Dra.",
        image: "ClaudiaGemelgo.png",
        nome: "Cláudia Gemelgo",
        especialidade: "Medicina Física e de Reabilitação",
        especialidades: ["medicinaFisicaReabilitacao","intervencionismoEcoguiado","consultaDaDor"],
        description: "Médica especialista em Medicina Física e de Reabilitação\n"
    }, {
        image: "DanielDuarte.png",
        prefixo: "Dr.",
        nome: "Daniel Duarte",
        especialidade: "Psicólogo",
        especialidades: ["psicologia"],
        description: "Psicólogo"
    }, {
        image: "AnaLeite.png",
        prefixo: "Dra.",
        nome: "Ana Leite",
        especialidade: "Psicólogo",
        especialidades: ["psicologia"],
        description: "Psicóloga"
    },{
        image: "CeuCarvalho.png",
        prefixo: "Dra.",
        nome: "Céu Carvalho",
        especialidade: "Psicólogo",
        especialidades: ["psicologia"],
        description: "Psicóloga"
    },

    {
        image: "DanielTeixeira.png",
        prefixo: "Enf.",
        nome: "Daniel Teixeira",
        especialidade: "Enfermeiro",
        especialidades: ["enfermagem"],
    }, {
        nome: "Bruno Ribeiro",
        image: "brunoJoel.jpeg",
        especialidade: "Treino Personalizado de Recuperação Física",
        especialidades: ["treinoPersonalizadoRecuperacaoFisica"],
        description: "Treino Personalizado de Recuperação Física"
    }, {
            nome: "Elsa Cruz",
            especialidade: "Treino Personalizado de Recuperação Física",
            especialidades: ["treinoPersonalizadoRecuperacaoFisica"],
            description: "Treino Personalizado de Recuperação Física"
        },  {
                        prefixo: "Dra.",
                        image:"FilipaGoncalves.png",
                        nome: "Filipa Gonçalves",
                        especialidade: "Psicólogo",
                        especialidades: ["psicologia"],
                        description: "Psicólogo"

                },

    // {
    //     image: "FilipaSousa.png",
    //     prefixo: "Dra.",
    //     nome: "Filipa Sousa",
    //     especialidade: "Terapeuta Ocupacional",
    //     especialidades: ["terapiaOcupacional"],
    //     description: "Terapeuta Ocupacional com especialização em Terapia da Mão"
    // },
    {
        image: "GabrielaRodrigues.png",
        prefixo: "Dra.",
        nome: "Gabriela Rodrigues",
        especialidade: "Gerontóloga",
        especialidades: ["gerontologia"],
        description: "Gerontóloga"
    }, {
        image: "JoanaLages.png",
        prefixo: "Dra.",
        nome: "Joana Lages",
        especialidade: "Pneumologista",
        especialidades: ["pneumologia"],
        description: "Pneumologista"
    },
   // {
     //   image: "JoaoVieira.jpeg",
      //  prefixo: "",
     //   nome: "João Vieira",
     //   especialidade: "Fisioterapeuta",
      //  especialidades: ["fisioterapia"]
    //},
     {
        prefixo: "Enf.",
        image: "JorgeSousa.png",
        nome: "Jorge Sousa",
        especialidade: "Enfermeiro",
        especialidades: ["enfermagem"],
    }, {
        prefixo: "Dra.",
        image:"JuditeSousa.png",
        nome: "Judite Sousa",
        especialidade: "Medicina Geral e Familiar",
        especialidades: ["medicinaGeralFamiliar", "medicinaDesportiva", "pequenaCirurgia", "emissaoDeAtestados", "atendimentoUrgente","examesMedicosDesportivos","intervencionismoEcoguiado"],
        description: "Médica especialista em Medicina Geral e Familiar",
        expertise: [
            "Diferenciada em Medicina de Urgência e Emergência, Traumatologia e Pequena Cirurgia"
        ],
        education: [
            "Mestrado em Ecografia Musculoesquelética e Intervencionismo Ecoguiado",
            "Pós-Graduada em Medicina do Exercício",
            "Pós-Graduada em Gestão e Direção de Serviços de Saúde",
            "Técnica Especialista em Exercício Físico"
        ]
    }, {
        image: "LilianaVasconcelos.png",
        prefixo: "Dra.",
        nome: "Liliana Vasconcelos",
        especialidade: "Podologista",
        especialidades: ["podologia"],
        description: "Podologista com formação no tratamento de úlceras do pé diabético, ortóteses corretivas e terapia por laser"
    }, {
        image: "MargaridaCosta.png",
        prefixo: "Dra.",
        nome: "Margarida da Costa",
        especialidade: "Nutricionista",
        especialidades: ["nutricao"],
        description: "Nutricionista",
        areas: [
            "Nutrição e Suplementação Desportiva"
        ]
    }, {
        prefixo: "Dra.",
        nome: "Margarida Marques",
        especialidade: "Medicina Geral e Familiar",
        especialidades: ["medicinaGeralFamiliar", "consultaDaDor", "atendimentoUrgente","examesMedicosDesportivos"],
        description: "Médica especialista em Medicina Geral e Familiar",
        expertise: [
            "Responsável pela Consulta de Dor Crónica"
        ],
        education: [
            "Pós-graduada em Cuidados Paliativos"
        ]
    }, {
        image: "MartaRibeiroSilva.png",
        prefixo: "Dra.",
        nome: "Marta Ribeiro Silva",
        especialidade: "Pediatra",
        especialidades: ["pediatria"],
        description: "Pediatra com diferenciação na área da patologia respiratória"
    }, {
        image: "PatriciaRodrigues.png",
        prefixo: "Dra.",
        nome: "Patrícia Rodrigues",
        especialidade: "Optometrista",
        especialidades: ["optometria"],
        description: "Optometrista com especialização na área da Posturologia e Performance Visual Desportiva"
    },  {
          prefixo: "Dr.",
          image:"RubenCastro.png",
          nome: "Rubén Castro",
          especialidade: "Fisioterapeuta",
          especialidades: ["fisioterapia"],
          description: "Fisioterapeuta"
    },
  //  {
      //    prefixo: "Dra.",
      //    image:"CeuFerreira.png",
      //    nome: "Céu Ferreira",
      //    especialidade: "Psiquiatria",
        //  especialidades: ["psiquiatria"],
        //  description: "Psiquiatra"
  //  },
    {
        prefixo: "Dr.",
        nome: "Sérgio Ferreira",
        especialidade: "Podologista",
        especialidades: ["podologia"],
        description: "Podologista com especialização na área de Podologia e Podologista Desportivo"
    }, {
        image: "SoniaAlmeida.png",
        nome: "Sónia Almeida",
        especialidade: "Administrativa"
    }, {
        image: "TiagoFernandes.png",
        prefixo: "Dr.",
        nome: "Tiago Fernandes",
        especialidade: "Oftalmologista",
        especialidades: ["oftalmologia", "medicinaEstetica"],
        description: "Médico especialista em Oftalmologia com diferenciação na área da Medicina Estética"
    }, {
        prefixo: "Dr.",
        nome: "Tiago Ribeiro",
        image: "TiagoRibeiro.png",
        especialidade: "Osteopata",
        especialidades: ["osteopatia"],
        description: "Osteopata"
    },{
        image: "JosePedroQueiros.png",
        prefixo: "Dr.",
        nome: "José Pedro Queirós",
        especialidade: "Terapia da Fala",
        especialidades: ["terapiaDaFala"],
        description: "Terapeuta da fala com diferenciação em intervenção terapêutica motora oral e facial."
    },{
          image: "SoniaSimoes.png",
          prefixo: "Dra.",
          nome: "Sónia Simões",
          especialidade: "Terapia da Fala",
          especialidades: ["terapiaDaFala"],
          description: "Terapeuta da fala com diferenciação em disfagias orofaríngeas e na prática clínica em paralisia cerebral e intervenção em alimentação"
    }
]

export const listProfissionais = () => {
    return list.sort((a, b) => {
        return a.nome.localeCompare(b.nome)
    })
}

export const listProfissionaisWithServico = (serviceId) => {
    return listProfissionais().filter(it => it.especialidades && it.especialidades.some(p => p === serviceId))
}
