import SobreNos from "../components/main/SobreNos";
import ContactUs from "../components/main/ContactUs";
import PorfolioProfissionais from "../components/corpoClinico/PorfolioProfissionais";
import {listProfissionais} from "../db/profissionais";

const MainPage = () => {
    return (
        <>
            <section id="sobre" className="about-us section" style={{paddingTop: "0px"}}>
                <div className="container">
                    <SobreNos />
                </div>
            </section>
            <section id="agendar" className="contact-us section">
                <div className="container">
                    <div className="contact-head wow fadeInUp" data-wow-delay=".4s">
                        <ContactUs />
                    </div>
                </div>
            </section>
            <section id="corpo-clinico" className="doctors section">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="section-title">
                                <h3>Corpo Clínico</h3>
                                <h2 className="wow fadeInUp" data-wow-delay=".4s">Corpo Clínico</h2>
                            </div>
                        </div>
                    </div>

                    <PorfolioProfissionais itemsPerRow={4} profissionais={listProfissionais()} />
                </div>

            </section>
        </>
    )
}

export default MainPage