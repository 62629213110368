import PorfolioProfissionais from "../corpoClinico/PorfolioProfissionais";

const ListServices = ({services}) => {
    return (
        <ul style={{listStyleType: "circle"}}>
            {services.map((it, i) => <li key={i}>{it}</li>)}
        </ul>
    )
}

const ServicosDoctorsList = ({info, profissionais}) => {
    return (
        <div id="listaProfissionais">
            <h3 className="title">{info.name}</h3>

            {info.description && <p>{info.description}</p>}

            {info.services && <ListServices services={info.services} />}

            {profissionais.length > 0 && <>
                <h4 className="sub-title">Os nossos especialistas</h4>
                <PorfolioProfissionais itemsPerRow={3} profissionais={profissionais} />
            </>}

        </div>
    )
}

export default ServicosDoctorsList