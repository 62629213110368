import {useForm} from "react-hook-form";
import emailjs from '@emailjs/browser';

emailjs.init("_nTsgwQdlj6UfyJs_");

const SendMessage = () => {
    const {register, handleSubmit, formState: {errors}} = useForm();
    const onSubmit = data => {
        emailjs.sendForm('default_service','template_vxfvi0j', data)
            .then(function(response) {
                console.log('SUCCESS!', response.status, response.text);
            }, function(error) {
                console.log('FAILED...', error);
            });
    };
    return (
        <>
            <form method="post" className="form" onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                    <div className="col-lg-6 col-12">
                        <div className="form-group">
                            <input type="text" className={errors.name ? "invalid": "valid"} placeholder="Nome" {...register("name", { required: true })} />
                            <div className={errors.name ? "error": "error vazio"}>Preencha o seu nome</div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-12">
                        <div className="form-group">
                            <input className={errors.subject ? "invalid": "valid"} type="text" placeholder="Assunto" {...register("subject", { required: true })}/>
                            <div className={errors.subject ? "error": "error vazio"}> Qual o assunto pretendido?</div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-12">
                        <div className="form-group">
                            <input className={errors.email ? "invalid": "valid"} type="email" placeholder="Email" {...register("email", { required: true })}/>
                            <div className={errors.email ? "error": "error vazio"}> Preencha o seu email</div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-12">
                        <div className="form-group">
                            <input className={errors.telefone ? "invalid": "valid"} type="text" placeholder="Telefone" {...register("telefone")}/>
                            <div className={errors.telefone ? "error": "error vazio"}> Preencha com o seu número de
                                telemóvel
                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="form-group message">
                            <textarea className={errors.message ? "invalid": "valid"} placeholder="A sua mensagem..." {...register("message", { required: true })}></textarea>
                            <div className={errors.message ? "error": "error vazio"}> Digite a sua mensagem</div>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="form-group button">
                            <button type="submit" className="btn">Enviar mensagem</button>
                        </div>
                    </div>
                </div>
            </form>
            <div className="mail-success hidden">
                <div className="d-table">
                    <div className="d-table-cell">
                        <div className="container">
                            <div className="success-content">
                                <h2>A sua mensagem foi enviada!</h2>
                                <p>Entraremos em contacto assim que possivel.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SendMessage