import {useHistory} from "../../providers/HistoryProvider";


const Link = ({path, children, className, childActive = false, checkActive = true, ignoreClick = false, callback = () => {}}) => {
    const history = useHistory()
    const onClick = () => {

        callback();
        if (!ignoreClick && path) {
            history.navigateTo(path)
        }
    }
    const isActive = (path) => {
        if (!checkActive) return false
        if (!childActive) {
            return history.current() === path
        } else {
            return history.current().startsWith(path)
        }
    }
    return (
        <a className={isActive(path) ? `active ${className}`: className} style={{cursor: "pointer"}} onClick={onClick}>{children}</a>
    )
}

export default Link