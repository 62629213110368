import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter } from "react-router-dom";
import {HistoryProvider} from "./providers/HistoryProvider";
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
        <HistoryProvider>
            <App />
        </HistoryProvider>
    </BrowserRouter>
);