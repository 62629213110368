import * as React from "react";
import {useLocation, useNavigate} from "react-router-dom";

class History {
    constructor(navigate, location) {
        this.navigate = navigate
        this.location = location
    }

    navigateTo (path) {
        if (this.current() !== path) {
            this.navigate(path)
        }
    }

    current () {
        return `${this.location.pathname}${this.location.hash}${this.location.search}`
    }
}

const HistoryContext = React.createContext(null)


export const HistoryProvider = ({ children }) => {
    const location = useLocation()
    const navigate = useNavigate()

    return (
        <HistoryContext.Provider value={new History(navigate, location)}>{children}</HistoryContext.Provider>
    );
};

export const useHistory = () => React.useContext(HistoryContext);