import Sidebar from "../components/parts/Sidebar";
import {Route, Routes, useParams} from "react-router-dom";
import ServicosDoctorsList from "../components/servicos/ServicosDoctorsList";
import {listServicosOfType} from "../db/especialidades";
import {listProfissionaisWithServico} from "../db/profissionais";
import {useHistory} from "../providers/HistoryProvider";

const ServicosPage = () => {
    let { type } = useParams()
    let servicos = listServicosOfType(type);
    let defaultService = servicos[0]
    let history = useHistory()

    const onSelect = (item) => {
        history.navigateTo(`/servicos/${type}/${item.id}`)
    }

    return (
        <div className="service-details doctors">
            <div className="container">
                <div className="content">
                    <div className="row">
                        <div className="col-lg-4 col-md-12 col-12">
                            <Sidebar title={type === "medicos" ? "Serviços médicos" : "Outros Serviços de Saúde"} servicos={servicos} onChange={onSelect} />
                        </div>
                        <div className="col-lg-8 col-md-12 col-12">
                            <div className="details-content">
                                <Routes>
                                    {servicos.map(it => <Route key={it.id} path={`/${it.id}`} element={<ServicosDoctorsList info={it} profissionais={listProfissionaisWithServico(it.id)} />} />)}
                                    <Route path="*" element={<ServicosDoctorsList info={defaultService} profissionais={listProfissionaisWithServico(defaultService.id)} />} />
                                </Routes>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ServicosPage