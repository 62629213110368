const list = [
    {id: "medicinaGeralFamiliar", name: "Medicina Geral e Familiar", type: "medicos"},
    {id: "medicinaDesportiva", name: "Medicina desportiva ", type: "medicos"},
    {id: "consultaDaDor", name: "Consulta da Dor", type: "medicos"},
    {id: "pediatria", name: "Pediatria", type: "medicos"},
    //{id: "dermatologia", name: "Dermatologia", type: "medicos"},
    //{id: "oftalmologia", name: "Oftalmologia", type: "medicos"},
    {id: "medicinaEstetica", name: "Oculoplástica e Estética Ocular", type: "medicos"},
    {id: "pneumologia", name: "Pneumologia", type: "medicos"},
    {id: "estomatologia", name: "Estomatologia", type: "medicos"},
    {id: "medicinaFisicaReabilitacao", name: "Medicina Física e de Reabilitação", type: "medicos"},
    {id: "pequenaCirurgia", name: "Pequena Cirurgia", type: "medicos"},
    {id: "intervencionismoEcoguiado", name: "Intervencionismo Ecoguiado", type: "medicos"},
    {id: "atendimentoUrgente", name: "Atendimento Urgente", type: "medicos", services: ["No consultório", "Teleconsulta", "Domicílio"]},
    {id: "emissaoDeAtestados", name: "Emissão de Atestados", type: "medicos", description: "Emissão de atestados para a carta de condução"},
    {id: "examesMedicosDesportivos", name: "Exames Médicos Desportivos", type: "medicos"},

    {
        id: "fisioterapia", name: "Fisioterapia", type: "outros", services: [
            "Fisioterapia ao Domicílio",
            "Fisioterapia Musculosquelética",
            "Fisioterapia do Pavimento Pélvico",
            "Fisioterapia Cardiorrespiratória",
            "Pilates Clínico",
            "Técnica Shantala",
            "Método Mézières"
        ]
    },
    {id: "enfermagem", name: "Enfermagem", type: "outros", services: ["Enfermagem ao Domicílio"]},
    {id: "osteopatia", name: "Osteopatia", type: "outros"},
    {id: "nutricao", name: "Nutrição", type: "outros"},
    {id: "psicologia", name: "Psicologia", type: "outros", services: ["Psicologia Clínica", "Psicologia de Performance Desportiva"]},
    {id: "optometria", name: "Optometria", type: "outros"},
    {id: "podologia", name: "Podologia", type: "outros"},
  //  {id: "terapiaOcupacional", name: "Terapia Ocupacional", type: "outros"},
    {id: "terapiaDaFala", name: "Terapia da Fala", type: "outros"},
    {id: "treinoPersonalizadoRecuperacaoFisica", name: "Treino Personalizado de Recuperação Física", type: "outros"},
    {id: "gerontologia", name: "Gerontologia", type: "outros"},
    //{id: "psiquiatria", name: "Psiquiatria", type: "outros"},
]


export const listServicosOfType = (type) => {
    return listAll().filter(it => it.type === type)
}


export const listAll = () => {
    return list.sort((a, b) => a.name.localeCompare(b.name))
}
