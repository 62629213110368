import {useState} from "react";

const SidebarItem = ({item, onSelect, checkBox = false}) => {
    if (checkBox) {
        return (<li>
            <div style={{
                padding: "12px 0",
                color: "#081828",
                fontWeight: "400",
                display: "block",
                borderRadius: 0,
                position: "relative",
                paddingRight: "40px",
                fontSize: "14px",
                borderBottom: "1px solid #eee"
            }}>{item.name} <input className="lni lni-arrow-right"
                                  style={{
                                      float: "right",
                                      fontSize: "14px",
                                      position: "absolute",
                                      right: "20px",
                                      height: "18px",
                                      top: "50%",
                                      marginTop: "-9px"
                                  }}
                                  type="checkbox" onChange={(e) => onSelect(item, e.target.checked)}/></div>
        </li>)
    }

    return (
        <li>
            <a style={{cursor: "pointer"}} onClick={() => onSelect(item, true)}>
                {item.name} <i className="lni lni-arrow-right"></i>
            </a>
        </li>
    )
}

const Sidebar = ({title, servicos, onChange, multiSelection = false}) => {
    const [selection, setSelection] = useState([])
    const [collapse, setCollapse] = useState(true)

    const onSelect = (item, selected) => {
        setCollapse(true)
        if (!multiSelection) {
            if (JSON.stringify(selection.map(it => it.id)) !== JSON.stringify([item.id])) {
                setSelection([item])
                onChange(item)
            }
        } else {
            let newSelection = selection.slice().filter(it => it.id !== item.id)
            if (selected) {
                newSelection.push(item)
            }

            if (JSON.stringify(selection.map(it => it.id)) !== JSON.stringify(newSelection.map(it => it.id))) {
                setSelection(newSelection)
                onChange(newSelection)
            }
        }
    }

    return (
        <div className="service-sidebar">
            <div className="single-widget service-category d-none d-sm-block">
                <h3>{title}</h3>
                <ul>
                    {servicos.map(it => <SidebarItem key={it.id} item={it} checkBox={multiSelection} onSelect={onSelect} />)}
                </ul>
            </div>
            <div className="single-widget service-category d-block d-sm-none">
                <h3  style={{cursor: "pointer"}} onClick={() => setCollapse(!collapse)}>{title} <i className={collapse ? "lni lni-arrow-right-circle" : "lni lni-arrow-down-circle"}></i></h3>
                <ul className={collapse ? "collapse" : ""}>
                    {servicos.map(it => <SidebarItem key={it.id} item={it} checkBox={multiSelection} onSelect={onSelect} />)}
                </ul>
            </div>
            <div className="single-widget address d-none d-sm-block">
                <ul>
                    <li><i className="lni lni-map-marker"></i>
                        <a>Av. Sto António, n. 31C <br/> 4720-343
                            Ferreiros - Amares</a>
                    </li>
                    <li><i className="lni lni-phone"></i>

                        <a className="color" href="tel:253 994 024">+351 253 994 024</a>

                        <a className="color" href="tel:+351925191319">+351 925 191 319</a>

                    </li>
                    <li>
                        <i className="lni lni-envelope"></i> <a
                        href="mailto:geral.sousaude@gmail.com">geral.sousaude@gmail.com</a>

                    </li>
                </ul>
            </div>
        </div>
    )
}

export default Sidebar