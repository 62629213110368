const ItemList = ({items, className}) => {
    if (!items) return <></>
    return (
        <ul className={className}>
            {items.map((it, i) => <li key={i}>{it}</li>)}
        </ul>
    )
}

const AreasIntervencao = ({areas}) => {
    if (!areas) return <></>
    return (
        <>
            <h4>Áreas de intervenção</h4>
            <ItemList items={areas} className="normal-list-info" />
        </>
    )
}

const Profissional = ({info}) => {
    return (
        <section className="doctor-details" style={{paddingTop: "0px", paddingBottom: "10px"}}>
            <div className="container" style={{maxWidth: "auto"}}>
                <div className="inner">
                    <div className="row">
                        <div className="col-12">
                            <div className="row">
                                <div className="col-lg-4 col-md-4 col-12">

                                    <div className="image text-center">
                                        <img style={{height: "200px", width: "auto"}} src={info.image ? `assets/images/doctors/${info.image}`: "assets/images/doctors/doctor.png"} alt={info.prefixo ? `${info.prefixo} ${info.nome}`: info.nome} />
                                    </div>

                                </div>

                                <div className="col-lg-8 col-md-8 col-12 corFundo">
                                    <div className="content">
                                        <h3 className="name">{info.nome}</h3>
                                        <p>{info.description}</p>
                                        <ItemList items={info.expertise} className="list-info" />
                                        <AreasIntervencao areas={info.areas} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}


const ListProfissionais = ({profissionais}) => {
    return (
        <>
            {profissionais.map((it, i) => <Profissional key={i} info={it} />)}
        </>
    )
}

export default ListProfissionais