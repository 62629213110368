import {listProfissionais} from "../db/profissionais";
import ListProfissionais from "../components/corpoClinico/ListProfissionais";
import Sidebar from "../components/parts/Sidebar";
import {listAll} from "../db/especialidades";
import {useState} from "react";

const CorpoClinicoPage = () => {
    let servicos = listAll().filter(s => listProfissionais().some(p => p.especialidades && p.especialidades.some(e => e === s.id)));
    let [profissionais, setProfissionais] = useState(listProfissionais().filter(it => it.description))

    const onSelectEspecialidade = (selected) => {
        let list = listProfissionais()
        if (selected.length === 0) {
            setProfissionais(list)
        } else {
            setProfissionais(list.filter(it => it.especialidades)
                .filter(it => it.especialidades.some(e => selected.some(s => s.id === e))))
        }
    }

    return (
        <div className="container" style={{marginTop: "20px"}}>
            <div className="row">
                <div className="col-4 d-none d-md-block">
                    <Sidebar title="Corpo Clinico" multiSelection={true} servicos={servicos} onChange={onSelectEspecialidade} />
                </div>
                <div className="col">
                    <ListProfissionais profissionais={profissionais} />
                </div>
            </div>
        </div>
    )
}

export default CorpoClinicoPage