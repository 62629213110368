import {Route, Routes} from "react-router-dom";
import MainPage from "./pages/MainPage";
import CorpoClinicoPage from "./pages/CorpoClinicoPage";
import ContactoPage from "./pages/ContactoPage";
import Header from "./components/parts/Header";
import Footer from "./components/parts/Footer";
import ServicosPage from "./pages/ServicosPage";

function App() {
    return (
        <>
            <Header/>
            <Routes>
                <Route path="/" element={<MainPage />} />
                <Route path="/corpoClinico" element={<CorpoClinicoPage />} />
                <Route path="/agendar" element={<ContactoPage />} />
                <Route path="/servicos/:type/*" element={<ServicosPage />} />
            </Routes>
            <Footer />
        </>
    );
}

export default App;
