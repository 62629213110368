

const SobreNos = () => {
    return (
        <>
            <div className="row align-items-center">
                <div className="col-lg-12 col-md-12 col-12">
                    <img style={{width: "100%", padding: "5px", margin: "10px", border:"solid 1px #ccc"}} src="/assets/images/about/LogoSmall.jpg"/>
                </div>
            </div>
            <div className="row align-items-center">
                <div className="col-lg-12 col-md-12 col-12">
                    <div className="content-right wow fadeInRight"  style={{paddingLeft: "10px", marginTop: "1em"}} data-wow-delay=".5s">
                        <p style={{marginBottom: "1em"}}>A SouSaúde é uma clínica localizada em Amares, que tem como objetivo primordial
                            promover a saúde e o bem-estar dos seus utentes, auxiliando e providenciando
                            ferramentas para que possam realizar as suas atividades de vida diárias de forma
                            eficiente e com a menor limitação possível.
                        </p>
                        <p style={{marginBottom: "1em"}}>
                            Para isso, dispomos de uma equipa de profissionais altamente qualificados em
                            diversas áreas de modo a disponibilizarmos os tratamentos mais adequados a cada
                            condição de forma individualizada, através de uma abordagem coesa, contínua e
                            interdisciplinar.
                        </p>
                        <p style={{marginBottom: "1em"}}>
                            O cuidar da pessoa é o nosso foco, em termos de prevenção, diagnóstico e
                            tratamento, quer a nível da saúde física como da saúde mental. Pretendemos caminhar
                            a seu lado auxiliando-o em todas as fases da sua vida.
                        </p>
                        <p style={{marginBottom: "1em"}}>
                            Inspirando-nos na visão inovadora do <i>Exercise is Medicine</i>, dispomos do serviço
                            de treino personalizado, em que se procede à prescrição e prática de exercício físico
                            com efeito terapêutico. Este modelo desenvolvido pelo Colégio Americano de Medicina
                            Desportiva alia a avaliação clínica de profissionais de saúde com a promoção e
                            prescrição de atividade física, uma prática com benefícios comprovados a nível
                            científico, quer a nível da prevenção como do tratamento da doença.
                        </p>
                        <p>
                            Venha conhecer as nossas instalações, devidamente equipadas e pensadas no seu
                            conforto e bem-estar.
                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SobreNos