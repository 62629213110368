import Link from "../navigation/Link";


const Footer = () => {
    return (

        <footer id="contacto" className="footer overlay">
            <div className="footer-middle">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-md-6 col-12">

                            <div className="single-footer f-about">
                                <div className="logo">
                                    <Link path="/">
                                        <img src="/assets/images/logo/white-logo.svg" alt="#" />
                                    </Link>
                                </div>
                                <p>Sempre a pensar na sua Saúde.</p>
                                <ul className="social">
                                    <li><a href="https://www.facebook.com/Sou-Sa%C3%BAde-107475035337528/"><i
                                        className="lni lni-facebook-filled"></i></a></li>
                                    <li><a href="https://www.instagram.com/sousaude_cmrf/"><i
                                        className="lni lni-instagram"></i></a></li>

                                </ul>
                            </div>

                        </div>
                        <div className="col-lg-3 col-md-6 col-12">

                            <div className="single-footer f-link">
                                <h3>Links úteis</h3>
                                <div className="row">
                                    <div className="col-lg-6 col-md-6 col-12">
                                        <ul>
                                            <li><Link path="/">Sobre Nós</Link></li>
                                            <li><Link path="/corpoClinico">Corpo Clinico</Link></li>
                                            <li><Link path="/agendar">Marcar consulta</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="col-lg-3 col-md-6 col-12">

                            <div className="single-footer opening-hours">
                                <h3>Horário de funcionamento</h3>
                                <ul>
                                    <li>
                                        <span className="day"><i className="lni lni-timer"></i> Segunda - Sexta</span>
                                        <span className="time">09:30 - 12:30</span><br/>
                                        <span className="time">14:00 - 19:00</span>
                                    </li>
                                    <li>
                                        <span className="day"><i className="lni lni-timer"></i> Sabádo </span>
                                        <span className="time">09:30 - 12:30</span>
                                    </li>
                                    <li>
                                        <span className="day"><i
                                            className="lni lni-timer"></i> Domingos e Feriados </span>
                                        <span className="time">encerrado</span>
                                    </li>
                                </ul>
                            </div>

                        </div>
                        <div className="col-lg-3 col-md-6 col-12">

                            <div className="single-footer last f-contact">
                                <h3>Contacto</h3>
                                <ul>
                                    <li>
                                        <i className="lni lni-map-marker"></i> Av. Sto António, n. 31C <br/> 4720-343
                                        Ferreiros - Amares
                                    </li>
                                    <li>
                                        <i className="lni lni-phone"></i><a className="color" href="tel:253 994 024">+351
                                        253 994 024</a>
                                    </li>
                                    <li>
                                        <i className="lni lni-phone"></i><a className="color" href="tel:+351925191319">+351
                                        925 191 319</a>
                                    </li>

                                    <li>
                                        <i className="lni lni-envelope"></i> <a
                                        href="mailto:geral.sousaude@gmail.com">geral.sousaude@gmail.com</a>
                                    </li>
                                </ul>
                            </div>

                        </div>
                    </div>
                </div>


            </div>

        </footer>
    )
}

export default Footer