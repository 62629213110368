import Link from "../navigation/Link";
import React from "react";




const Header = () => {
    let menuButton = React.createRef();

    let clickCallback = () => {
        if (menuButton.current)
            menuButton.current.click();
    };

    return (
        <header className="header navbar-area">
            <div className="top-bar">
                <div className="container">
                    <div className="inner-topbar">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-12">
                                <div className="top-contact">
                                    <ul>
                                        <li><i className="lni lni-envelope"></i><a
                                            href="mailto:geral.sousaude@gmail.com">geral.sousaude@gmail.com</a></li>
                                        <li><i className="lni lni-phone"></i> <a className="color"
                                                                                 href="tel:253 994 024">+351 253 994
                                            024</a></li>
                                        <li><i className="lni lni-phone"></i> <a className="color"
                                                                                 href="tel:+351 925 191 319">+351 925
                                            191 319</a></li>
                                    </ul>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6 col-12">
                                <div className="right-content">
                                    <div className="top-social">
                                        <ul>
                                            <li><a href="https://www.facebook.com/Sou-Sa%C3%BAde-107475035337528/"><i
                                                className="lni lni-facebook-filled"></i></a>
                                            </li>
                                            <li><a href="https://www.instagram.com/sousaude_cmrf/"><i
                                                className="lni lni-instagram"></i></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-12">
                        <div className="nav-inner">
                            <nav className="navbar navbar-expand-lg">
                                <Link className="navbar-brand" path="/">
                                    <img src="/assets/images/logo/logo.svg" alt="Logo" />
                                </Link>
                                <button className="navbar-toggler mobile-menu-btn" type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                                        aria-expanded="false" aria-label="Toggle navigation" ref={menuButton}>
                                    <span className="toggler-icon"></span>
                                    <span className="toggler-icon"></span>
                                    <span className="toggler-icon"></span>
                                </button>
                                <div className="collapse navbar-collapse sub-menu-bar" id="navbarSupportedContent" >
                                    <ul id="nav" className="navbar-nav ms-auto">
                                        <li className="nav-item">
                                            <Link path="/" callback={clickCallback}>Sobre Nós</Link>
                                        </li>
                                        <li className="nav-item d-none d-md-block">
                                            <Link path="/servicos" ignoreClick={true} childActive={true}>Serviços</Link>
                                            <ul className="sub-menu collapse" id="submenu-1-1">
                                                <li className="nav-item">
                                                    <Link path="/servicos/medicos" checkActive={false}>Serviços médicos</Link>
                                                </li>
                                                <li className="nav-item">
                                                    <Link path="/servicos/outros" checkActive={false}>Outros serviços de saúde</Link>
                                                </li>
                                            </ul>
                                        </li>
                                        <li className="nav-item d-block d-sm-none">
                                            <Link path="/servicos/medicos" callback={clickCallback}>Serviços médicos</Link>
                                        </li>
                                        <li className="nav-item d-block d-sm-none">
                                            <Link path="/servicos/outros"  callback={clickCallback}>Outros serviços de saúde</Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link path="/corpoClinico"  callback={clickCallback}>Corpo Clínico</Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link path="/agendar"  callback={clickCallback}>Contacto</Link>
                                        </li>
                                    </ul>
                                </div>
                                <div className="button add-list-button">
                                    <Link path="/agendar" className="btn">Marcar consulta</Link>
                                </div>
                            </nav>

                        </div>
                    </div>
                </div>
            </div>
        </header>
    )
}

export default Header