import SendMessage from "../communication/SendMessage";


const ContactUs = () => {
    return (
        <>
            <div className="row">
                <div className="col-12">
                    <div className="section-title">
                        <h3>Consultas</h3>
                        <h2 className="wow fadeInUp" data-wow-delay=".4s"> Envie a sua mensagem</h2>
                        <p className="wow fadeInUp" data-wow-delay=".6s">Se quer marcar uma consulta ou
                            tirar alguma dúvida
                            <br/> contacte-nos e entraremos em contacto assim que possível!
                        </p>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-8 col-12">
                    <div className="form-main">
                        <div className="form-title">
                            <h2>Envie a sua mensagem</h2>
                        </div>


                        <SendMessage />
                    </div>
                </div>
                <div className="col-lg-4 col-12">
                    <div className="single-head">
                        <h2 className="main-title">Contactos</h2>
                        <div className="single-info">
                            <div className="info-icon">
                                <i className="lni lni-map-marker"></i>
                            </div>
                            <h3>Morada</h3>
                            <ul>
                                <li>Av. Sto António, n. 31C <br/> 4720-343 Ferreiros - Amares</li>
                            </ul>
                        </div>
                        <div className="single-info">
                            <div className="info-icon">
                                <i className="lni lni-timer"></i>
                            </div>
                            <h3>Horário</h3>
                            <ul>
                                <li>Seg. - Sex. 09:30 - 12:30</li>
                                <li>14:00 - 19:00</li>
                                <li>Sáb. 09:30 - 12:30</li>
                                <li>Dom. - Feriados encerrado</li>
                            </ul>
                        </div>
                        <div className="single-info">
                            <div className="info-icon">
                                <i className="lni lni-envelope"></i>
                            </div>
                            <h3>Email</h3>
                            <ul>
                                <li><a href="mailto:geral.sousaude@gmail.com">geral.sousaude@gmail.com</a>
                                </li>
                            </ul>
                        </div>
                        <div className="single-info">
                            <h3>Telefone</h3>
                            <div className="info-icon">
                                <i className="lni lni-mobile"></i>
                            </div>
                            <ul>
                                <li><a href="tel:+351253994024">+351 253 994 024</a></li>
                                <li><a href="tel:+351925191319">+351 925 191 319</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ContactUs