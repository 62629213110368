import ContactUs from "../components/main/ContactUs";

const MainPage = () => {
    return (
        <section id="agendar" className="contact-us section">
            <div className="container">
                <div className="contact-head wow fadeInUp" data-wow-delay=".4s">
                    <ContactUs />
                </div>
            </div>
        </section>
    )
}

export default MainPage